<template>
  <div>
    <div class="training-language-course mx-auto position-relative mt-3">
      <div class="text-right mb-2">
        <CButton color="primary" to="/tfw/placements">Back</CButton>
      </div>
      <img
        ref="myImage"
        src="/img/tfw/placements/placements_view.png"
        width="1154px"
        @click="onImageClick"
      />
    </div>
    <div class="tfw-course">
      <Modal
        :modalTitle="confirmModal.modalTitle"
        :modalColor="confirmModal.modalColor"
        :modalContent="confirmModal.modalContent"
        :isShowPopup="confirmModal.isShowPopup"
        :buttons="confirmModal.buttons"
        :modalCallBack="confirmModalCallBack"
        :closeOnBackdrop="false"
        v-if="confirmModal.isShowPopup"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/reusable/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      confirmModal: {
        modalColor: "modal-primary",
        modalTitle: "Confirmation",
        modalContent: "Click Confirm to Register & Apply Now",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      coOrdinates: [
        {
          x: {
            start: 782,
            end: 1122,
          },
          y: {
            start: 1111,
            end: 1163,
          },
        },
        // {
        //   x: {
        //     start: 900,
        //     end: 336,
        //   },
        //   y: {
        //     start: 1088,
        //     end: 359,
        //   },
        // },
        // {
        //   x: {
        //     start: 898,
        //     end: 611,
        //   },
        //   y: {
        //     start: 1085,
        //     end: 633,
        //   },
        // },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchOrgDetailsByAcronym", "fetchOrgFilesByAcronym"]),
    async confirmModalCallBack(action) {
      if (action === "Confirm") {
        console.log("hii");
        await this.fetchOrgDetailsByAcronym('prstg')
        await this.fetchOrgFilesByAcronym('prstg')
        this.$router.push("/prstg/signup");
      }
      this.confirmModal.isShowPopup = false;
    },
    onImageClick(event) {
      const image = this.$refs.myImage;
      const boundingRect = image.getBoundingClientRect();
      const xPosition = event.clientX - boundingRect.left;
      const yPosition = event.clientY - boundingRect.top;

      const isClicked = this.coOrdinates.some((v) => {
        const { x, y } = v;
        const xCond = x.start <= xPosition && x.end >= xPosition;
        const yCond = y.start <= yPosition && y.end >= yPosition;
        return xCond && yCond;
      });
      if (isClicked) {
        this.confirmModal.isShowPopup = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.training-language-course {
  width: 1150px;
}
.btn-login {
  position: absolute;
  right: 0;
  background-color: #fff;
  margin-right: 18px;
  padding-bottom: 0px;
  width: 200px;
  color: #1d3787;
}
</style>
